import React, { useState } from "react";
import { currentUser } from "@utils/PortalContext";
import { LoadingSpinner } from "@scandotcom/react";

export const PartnerSwitcher = () => {
  const user = currentUser();
  const [loading, setLoading] = useState(false);

  const switchPartner = (e) => {
    e.target.parentElement.submit();
    setLoading(true);
  };

  if (!user) {
    return null;
  }

  return (
    <div className="gap-x-1.5 py-1.5 sm:ml-10 sm:flex">
      {loading && <LoadingSpinner />}

      {user.partners.length > 1 && !loading && (
        <form method="post" action="/users/switch">
          <input
            type="hidden"
            name="authenticity_token"
            value={window.AppData.CsrfToken}
          />
          <input
            type="hidden"
            name="return_path"
            value={document.location.pathname}
          />
          <select
            className="form-select text-md rounded-md px-1 py-1 text-center font-medium leading-6 text-gray-700"
            onChange={switchPartner}
            name="partner_public_code"
            value={user.partnerPublicCode}
          >
            {user.partners.map((partner) => (
              <option key={partner.publicCode} value={partner.publicCode}>
                {partner.name}
              </option>
            ))}
          </select>
        </form>
      )}
    </div>
  );
};
