import { Disclosure } from "@headlessui/react";
import { ScanLogo } from "components/common/ScanLogo";
import useNotifications from "hooks/useNotifications";
import { useOptimizedWindowSize } from "hooks/useOptimizedWindowSize";
import React from "react";
import { Link } from "react-router-dom";
import { currentUser } from "utils/PortalContext";
import { HamburgerButton } from "./HamburgerButton";
import { NavigationLink } from "./NavigationLink";
import { NotificationsMenu } from "./NotificationsMenu";
import { NotificationsMobileMenu } from "./NotificationsMobileMenu";
import { UserMenu } from "./UserMenu";
import { PartnerSwitcher } from "../../components/portal/PartnerSwitcher";

const navigation = [
  { name: "My Referrals", to: "/" },
  { name: "Patients", to: "/patients" },
  {
    name: "Centres Near Me",
    to: "/centres-near-me",
    className: "ref__centres",
  },
];

export const HeaderMenu = () => {
  const { all, read, unread } = useNotifications();
  const userData = currentUser();

  const showPaymentInfoLink = Boolean(userData?.canEditPaymentInfo);

  const { isMobile } = useOptimizedWindowSize({
    isMobile: ({ width }) => width < 640,
  });

  const partnerLogo = window.AppData.PartnerPortalSettings?.logo_url;
  const customBgColour =
    window.AppData.PartnerPortalSettings?.header_background_colour;

  return (
    <Disclosure
      as="nav"
      className="bg-cypress-800 py-3.5 text-cream-200"
      style={{ backgroundColor: customBgColour }}
    >
      {({ open }) => (
        <>
          <div className="container flex items-center">
            <HamburgerButton
              isToggled={open}
              className="humberger-button sm:hidden"
            />
            {/* Nav Logo */}
            <Link to="/" className="ml-auto text-cream-50 sm:ml-0">
              {partnerLogo ? (
                <img className="h-11 w-auto" src={partnerLogo}></img>
              ) : (
                <>
                  <span className="sr-only">Scan.com</span>
                  <ScanLogo className="h-7 w-auto" aria-hidden />
                </>
              )}
            </Link>

            {/* Desktop Nav Links */}
            <div className="hidden gap-x-1.5 sm:ml-10 sm:flex">
              {navigation.map((item) => (
                <NavigationLink key={item.name} {...item} />
              ))}

              <PartnerSwitcher />
            </div>

            <div className="ml-auto">
              {isMobile ? (
                <NotificationsMobileMenu
                  all={all}
                  read={read}
                  unread={unread}
                />
              ) : (
                <NotificationsMenu all={all} read={read} unread={unread} />
              )}
            </div>

            <UserMenu
              className="ml-1.5"
              showPaymentInfoLink={showPaymentInfoLink}
            />
          </div>

          {/* Mobile Menu */}
          <Disclosure.Panel className="mobile-hamburger-menu sm:hidden">
            <div className="space-y-1.5 px-2 pt-4">
              {navigation.map((item) => (
                <NavigationLink {...item} key={item.name} isMobile />
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
